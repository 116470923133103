@import '../../../Styles/shared.scss';

.container{
  height: calc(100% - 80px);
  box-sizing:border-box;
  padding:15px 0px 5px 0;
  width: 100%;
  position: relative;

  &.synod{
    padding-top: 0 !important;
  }

  .top{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing:border-box;
    padding:0px 15px;
  }

  .chevron{
    height:40px;
    width:40px;
    background: url('../../../Assets/Icons/chevron_blue.svg') no-repeat center/15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover{
      transform: scale(0.95);
    }
  }

  .wordCount{
    height: 45px;
    position: absolute;
    left: 80px;
    top: 80px;    
    display: flex;
    align-items: center;
    color: $E_blue_dark;

    &.uploadsHidden {
      left: 20px;
    }
  }

  .gradeContainer{
    display: flex;
    position: absolute;
    left: 160px;
    top: 83px;
    align-items: center;

    *{
      color:$blue;
    }

    .gradeCircle{
      border-radius: 50%;
      height: 40px;
      width: 40px;
      border: 3px solid $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }

  .editorDiv{
    width: 100%;
    height: 100%;
    position: relative;
    
    &.privateResponsive{
      @media (max-width:650px){
        background-color: rgb(194, 194, 194);
      }
    }

    .privateModeDiv{
      display: flex;
      align-items: center;
      position: absolute;
      top: 65px;
      left: 20px;
      height: 40px;
      // box-sizing:border-box;
      // padding:3px 0 0 0;
      
      .icon{
        height: 30px;
        border-radius: 5px;
        width: 35px;
        background: url('../../../Assets/Icons/private_black.svg') no-repeat center;
        background-size: 30px;
        margin-right: 10px;
      }

      .sliderDiv{
        // height: 150px;
        display: flex;
        align-items: center;
        box-sizing:border-box;
        position: relative;
        margin-right: 5px;
        z-index: 100;
  
        p{
          color: #858585;
          font-size: 15px;
          opacity: 0;
          transition: .2s all ease-out;
  
          &.show{
            opacity: 1;
          }
        }
  
        .slider{
          height: 35px;
          width: 55px;
          border-radius: 20px;
          background-color: #d2d2d2;
          margin: 0 10px;
          position: relative;
  
          .sliderInner{
            height: 29px;
            width: 29px;
            border-radius: 50%;
            background-color: white;
            position: absolute;
            left: 3px;
            top:3px;
            transition: .2s all ease-out;
            cursor: pointer;
  
            &.active{
              left:23px;
            }
          }
  
          &.active{
            background-color: $main-green;
          }
          
        }
      }

      .tip{
        position: relative;
        height: 20px;
        width: 20px;
        background: url('../../../Assets/Icons/question-light.svg')no-repeat center;
        margin-left: 10px;
        cursor: pointer;
        background-size: 18px;
        
  
        .tooltip{
          transition: .15s all ease-out;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          background-color: white;
          width: 350px;
          border-radius: 8px;
          padding:10px 15px;
          height: 70px;
          display: flex;
          align-items: center;
          font-size: 13px;
          color: #333333;
          top: -10px;
          opacity: 0;
          pointer-events: none;
          z-index: 100;
          text-align: center;
          font-weight: 500;
          bottom:3px;
          -webkit-box-shadow: 0px 1px 10px 0px rgba(6, 0, 0, 0.3);
          -moz-box-shadow:    0px 1px 10px 0px rgba(6, 0, 0, 0.3);
          box-shadow:         0px 1px 10px 0px rgba(6, 0, 0, 0.3);
        }
  
        &:hover{
          background: url('../../../Assets/Icons/question.svg')no-repeat center;
          background-size: 18px;
  
          .tooltip{
            top: 40px;
            opacity: 1;
          }
        }
      }
    }

    .uploadBtn{
      position: absolute;
      left: 10px;
      top: 60px;
      height: max-content;
      transition: .5s top .5s ease-out;

      input[type=file]{
        display: none;
      }
  
      .uploadTooltip{
        padding: 10px 20px 15px;
        position: absolute;
        opacity: 0;
        top: 50px;
        // right: 40px;
        pointer-events: none;
        -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
        box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
        background-color: white;
        z-index: 100;
        border-radius: 5px;
        transition: .2s all ease-in-out;
  
        p{
          font-size: 11px;
          width: 140px;
          text-align: center;
          color: #333333;
          font-weight: 300;
  
          &:first-of-type{
            margin-bottom: 5px;
          }
  
          span{
            font-size: 10px;
            color: $blue;
          }
        }
  
        &.limitReached{
          padding-bottom: 8px;
        }
      }
  
      .uploadBtnInner{
        height: 45px;
        width: 55px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
        box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
  
        img{
          height: 22px;
          
          &:first-of-type{
            height: 24px;
          }
        }
  
          &.limitReached{
            opacity: .4;
          }
      }
  
      // &.open{
      //   right: 10px;
      //   top: 60px;
      // }
  
      &:hover{
        .uploadTooltip{
          opacity: 1;
        }
      }
    }
  }

  .bottom{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing:border-box;
    padding:0 8px;

    &.withFiles{
      justify-content: space-between;
    }

  }
  
  .filesDiv{
    width: calc(100% - 230px);
    height: 100px;
    display: flex;
    align-items: center;
    box-sizing:border-box;
    padding:0 10px;

    .fileDiv{
      height: 80px;
      width: 115px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      cursor: pointer;
      position: relative;

      *{
        border-radius: 10px;
      }

      &.pdfFile{
        border:2px solid #077CA0;
        box-sizing:border-box;
        padding:8px 0 0 0;
      }
      
      &.placeholder{
        border:1px solid #077ca075;
        background-color: rgba(128, 128, 128, 0.05);

        img{
          height: 80px;
          object-fit: contain;
        }
      }

      p{
        font-weight: 500;
        margin-top: 3px;
        font-size: 13px;
        color:#077CA0;
      }

      img{
        height: 100%;
        width: 100%;
        object-fit: contain;

        &.pdfFile{
          height: 30px;
          object-fit: contain;
        }
      }

      .hoverDiv{
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left:0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.6);
        transition: .15s all ease-out;
        opacity: 0;

        img.close{
          position: absolute;
          top: -5px;
          right:-5px;
          height:20px;
          width: 20px;
          cursor: pointer;
        }
        
        
        .viewBtn{
          height: 30px;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 0.8);
          opacity: 0;
          pointer-events: none;
          transition: .15s all ease-out;

          p{
            margin:0;
            font-size: 14px;
            color: #077CA0;
            position: relative;
            top: 1px;
          }

          img{
            height: 18px;
            width: 30px;
            object-fit: contain;
            margin-right: 5px;
          }
        }
      }
      &:hover{
        .hoverDiv{
          opacity: 1;
          .viewBtn{
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }
  
  h5.saveBtn{
    cursor: pointer;
    color: $main-teal;
    margin-right: 5px;
    width: 60px;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover{
      text-decoration: underline;
      transform: scale(0.95);

    }
  }

  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    
    h5.saveBtn{
      cursor: pointer;
      color: $main-teal;
      margin-right: 5px;
      width: 60px;
      text-align: center;
      transition: all 0.3s ease-in-out;
  
      &:hover{
        text-decoration: underline;
        transform: scale(0.95);
  
      }
    }



    h5.voteBtn {
      height: 50px;
      color: white;
      padding: 10px 20px;
      border-radius: 8px;
      font-size: 1rem;
      cursor: pointer;
      background: rgb(6,95,229);
      background: linear-gradient(180deg, rgba(6,95,229,1) 0%, rgba(142,0,240,1) 66%);
    }
  }

  @media (max-width:1175px){
    .filesDiv{
      .fileDiv{
        width: 20%;
      }
    }
  }

  @media (max-width:650px){
    padding: 0;
    height: var(--viewportHeight);

    .bottom{
      height: 60px;
    }

    .editorDiv{
      box-sizing:border-box;
      padding:0 0 50px  0 ;
      height: calc(100% - 50px);

      &.synod{
        height: calc(100% - 50px);
      }

      .privateModeDiv{
        top: initial;
        bottom: 25px;
        left: 5px;
        width: 100vw;

        .tip{ 
          position: initial;
          
          &:hover{
          .tooltip{
            top: -85px;
            opacity: 1;
          }
        }
      }
      }
    }

    .filesDiv{
      display: none !important;
    }

    .wordCount, .uploadBtn, .gradeContainer{
      display: none;
    }
  }
}