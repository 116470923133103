@import '../../../Styles/shared.scss';

.container{
  height: 60px;
  display: flex;
  align-items: center;
  width: fit-content;

  &.onTopOfModal{
    z-index: 1100;
    pointer-events: none
  }

  p.closed{
    font-weight: 700;
    font-size: 17px;
    color:$main-teal;
    margin-right: 20px
  }

  .button{
    background-color: $main-teal;
    color: white;
    font-size: 14px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-radius: 8px;
    transition: .3s ease-in-out background-color;
    cursor: pointer;

    .tooltip{
      position: absolute;
      font-size: 11px;
      padding: 5px 10px;
      border-radius: 8px;
      color: #333333;
      text-align: center;
      transition: .3s all ease;
      pointer-events: none;
      opacity: 0;
      top: -40px;
      left: 50%;
      z-index: 5;
      width: fit-content;
      transform: translateX(-50%);
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    }
    
    h5{
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 0px);
      transition: .3s ease-in-out background-color;

      &.showRes{
        display: none;
      }
    }

    &.submit{
      padding-right: 50px;
      background: url('../../../Assets/Icons/coins.svg') no-repeat center right 19px, $main-teal;

      &:hover{
        .tooltip{
          top: -70px;
          opacity: 1;
        }
      }
    }

    &.resubmit{
      padding: 0 30px;
    }
  }

  
  .approvedDiv{

    height: 60px;
    display: flex;
    align-items: center;
    padding-right: 20px;

    p{
      color: $main-teal;
      font-size: 16px;
      margin: 0 10px;
      font-weight: 700;
    }

    img.boomer{
      height: 55px;
    }

    img.horn{
      height: 35px;
    }

    &.mobile{
      height: 45px;
      padding: 0;
      position: relative;
      right: -5px;

      img.boomer{
        display: none;
      }
      img.horn{
        height: 20px;
        position: relative;
        right: 5px;
        bottom: 4px;
      }
      p{
        color: #FFF69E;
        font-size: 12px;
      }
    }

  }

  @media (max-width:650px){
    // @include solidButton($main-teal--dark, white, 12px, 35px, 80px);
    background-image: none;
    justify-content: center;
    padding: 0;
    z-index: 20;

    .button{
      right: 0;
    
      &.submit{
        background: $E_blue;
        padding: 0 20px;
      }
    }

    h5{

      &.showRes{
        display: initial !important;
      }

      &.hideResText{
        display: none;
      }
    }
  }
  
}